
import { ref } from "vue";

import globalAxios from "axios";

import { Options, mixins } from "vue-class-component";
import ProductView from "./components/viewer/ProductView.vue";
import FabricColourPicker from "./components/editor/FabricColourPicker.vue";
import TextAdd from "./components/editor/TextAdd.vue";
import TextEditor from "./components/editor/TextEditor.vue";
import FeaturesTextEditor from "./components/editor/FeaturesTextEditor.vue";
import FeaturesPicker from "./components/editor/FeaturesPicker.vue";
import ArtAssetPicker from "./components/editor/ArtAssetPicker.vue";
import StyleDesignPicker from "./components/editor/StyleDesignPicker.vue";
import ArtAssetUpload from "./components/editor/ArtAssetUpload.vue";
import ResetTemplate from "./components/editor/ResetTemplate.vue";
import CommonActions from "./components/editor/CommonActions.vue";
import PriceTracker from "./components/editor/PriceTracker.vue";
import ControlSections from "./components/editor/ControlSections.vue";
import GeneralOptions from "./components/editor/GeneralOptions.vue";
import PurchaseOptions from "./components/editor/PurchaseOptions.vue";
import ProductSelector from "@/features/product-builder/components/editor/ProductSelector.vue";
import CustomizationPicker from "@/features/product-builder/components/editor/CustomizationPicker.vue";
import SelectedItemTrigger from "@/features/product-builder/components/editor/SelectedItemTrigger.vue";
import { IsLoggedIn, UploadPath, HandleError, HasAdminRoles } from "@/mixins/utilities";
import { useRoute } from "vue-router";
import CustomSpinner from "@/components/spinner/CustomSpinner.vue";
import CustomFullPageSpinner from "@/components/spinner/CustomFullPageSpinner.vue";
import Spinner from "@/components/spinner/Spinner.vue";
import SizeNameSelector from "@/components/checkout-process/cart/SizeNameSelector.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import PreviewImage from "@/components/images/PreviewImage.vue";
import BasicVueModal from "@/components/misc/BasicVueModal.vue";
import ShareModal from "@/components/share/ShareModal.vue";
import {
  CustomizationType,
  ProductBuilderFabricChannelViewModel,
  CustomizedProductItemViewModel,
  PriceViewModel,
  ProductBuilderCustomizationViewModel,
  ProductBuilderDesignViewModel,
  ProductBuilderExtraViewModel,
  ProductBuilderStyleViewModel,
  StoreViewModel,
  StoreStatus,
  StoreCustomizedProductListViewModel,
  StoreCustomizedProductsPostRequest,
  StoreCustomizedProductsIdDeleteRequest,
  ExtraAdminViewModel,
  ApplicationError,
} from "@/api-client";
import { CartItems, CustomizedProducts, ProductBuilder, StoreCustomizedProducts, Stores, AdminExtras, AdminExtraCustomizedProductItemTemplate } from "@/network/api";
import { store } from "@/store";
import { replaceAllInString } from "svg-text-to-path";
import mapHandler from "svg-text-to-path/handlers/map.js";
import { ControlSection, EnableSave, SaveType, SelectedItem, SelectedFeatureItem } from "@/types/index";
import { CustomPattern } from "@/store/modules/user";
// import { Canvas } from "fabric/fabric-impl";

@Options({
  components: {
    SizeNameSelector,
    SelectedItemTrigger,
    CustomSpinner,
    ResetTemplate,
    PurchaseOptions,
    GeneralOptions,
    ControlSections,
    PriceTracker,
    Spinner,
    ProductView,
    FabricColourPicker,
    TextAdd,
    TextEditor,
    FeaturesTextEditor,
    FeaturesPicker,
    ArtAssetPicker,
    StyleDesignPicker,
    ArtAssetUpload,
    CommonActions,
    ProductSelector,
    ShareModal,
    CustomizationPicker,
    PreviewImage,
    Breadcrumbs,
    BasicVueModal,
    CustomFullPageSpinner,
  },
  props: {
    originSlug: { type: String, default: "" },
  },
})
export default class ProductDesigner extends mixins(UploadPath, IsLoggedIn, HandleError, HasAdminRoles) {
  debug = false;
  shareId = "";
  selectedStyle: ProductBuilderStyleViewModel = {
    id:	"",
    name:	"",
    code:	"",
    previewImageUrl:	"",
    frontShadowMaskUrl:	"",
    backShadowMaskUrl:	"",
    designs:	[],
    extras:	[],
    prices: []
  };
  selectedDesign: ProductBuilderDesignViewModel = {
    id:	"",
    name:	"",
    code:	"",
    imageUrl:	"",
    fabricChannels:	[],
    layers:	[],
    customizations:	[],
    prices:	[]
  };
  showControls = true;
  showGeneral = true;
  showContent = false;
  showTools = false;
  toolsLocation = {
    top: 130,
    left: 40,
  };
  allPrices: Map<string, Array<PriceViewModel>> = new Map();
  maxParentHeight = 630;
  maxChildHeight = 500;
  showAddToCartChoices = false;
  showShareModal = false;
  showAnonSaveMessage = false;
  anonMessageDisplayCount = 0;
  selectedStyleDesignColourTab = "styles";
  selectedItem: SelectedItem | null = null;
  designState: any = ref();
  quantity = 25;
  selectedTextFeature: any = null;
  productId = "";
  productTitle = "";
  previewPath = "";
  savedCustomizedProductId = "";
  magicSeparator = "~";
  designTitle = "";
  originSlug = "";
  isAdminOrder = false;
  isAdminOrderEnquiry = false;
  isAdminConfigureExtra = false;
  adminConfigureExtraId: string | null = null;
  extraInfo: ExtraAdminViewModel = {
    id: "",
    name: "",
    code: "",
    previewImageUrl: "",
    frontShadowMaskUrl: "",
    backShadowMaskUrl: "",
    isDisabled: true,
    hasCustomizationText: true,
    customizedProductItemTemplate: {
      type: "",
      x: 0,
      y: 0,
      colour: "",
      rotate: 0,
      fontSize: 0,
      order: 0,
      text: "",
      view: 0,
      height: 0,
      width: 0,
      alignment: "",
      src: "",
      borderColour: "",
      borderStyle: 0,
      borderWidth: 0,
      fontWeight: "",
      fontStyle: "",
      textSvgUrl: "",
      frontPreviewImageUrl: "",
      backPreviewImageUrl: "",
      customization: {
        id: "",
        name: "",
        code: "",
        customizationTypeId: CustomizationType.Text
      }
    },
  };
  loading = false;
  fontPathKey = 0;
  user = null;
  enableSave: EnableSave = {
    customer: undefined,
    admin: undefined,
  };
  designing = false;
  lockQty = false;
  setPrevColour = false;
  isInCurrentCart = false;
  styleArrow = false;
  storeId = "";
  storeInfo: StoreViewModel = {
    id: "",
    countryId: "",
    countryName: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: "",
  };
  storeProducts: Array<StoreCustomizedProductListViewModel> = [];
  storeProduct: StoreCustomizedProductListViewModel = {
    id: "",
    expectedQuantity: 0,
    price: 0,
    customizedProductPreviewImageUrl: "",
    customizedProductBackPreviewImageUrl: "",
    customizedProductTitle: "",
    customizedProductName: "",
    customizedProductStyleId: "",
    name: "",
  };
  isInStore = false;
  canUpdateQuantity = ["Cart", "CartValidated", "PreparingQuote", "QuoteProvided", "AmendQuote"];
  vh = 0;
  sectionHeight = 0;
  savePatternMsg = "Please save your pattern before taking this action.";
  sections: ControlSection[] = [
    {
      name: "Style",
      id: "style",
      icon: "/img/designer/style-icon.png",
      disabledOnConfigureExtra: false,
    },
    {
      name: "Text",
      id: "text",
      icon: "/img/designer/text-icon.png",
      disabledOnConfigureExtra: false,
    },
    {
      name: "Art",
      id: "art",
      icon: "/img/designer/art-icon.png",
      disabledOnConfigureExtra: true,
    },
    {
      name: "Upload",
      id: "upload",
      icon: "/img/designer/upload-icon.png",
      disabledOnConfigureExtra: true,
    },
    {
      name: "Extras",
      id: "features",
      icon: "/img/designer/features-icon.png",
      disabledOnConfigureExtra: true,
    },
    {
      name: "Reset",
      id: "resetTemplate",
      icon: "/img/designer/reset-icon.png",
      disabledOnConfigureExtra: false,
    },
  ];
  selectedSection = this.sections[0];
  itemUpdateSizeError = false

  get cartId() {
    return store.getters["checkout/cartId"];
  }

  get cart() {
    return store.getters["checkout/cart"];
  }

  get adminExtraOverride() {
    return this.isAdminOrder && this.selectedTextFeature?.isIndividualCustomization;
  }

  get orderEnquiryNumbers() {
    if (this.designState) {
      if (this.designState.orderReferenceNumber && this.designState.enquiryReferenceNumbers?.length) {
        return `order ${this.designState.orderReferenceNumber} and enquir${this.designState.enquiryReferenceNumbers.length > 1 ? "ies" : "y"} ${this.designState.enquiryReferenceNumbers.join(", ")}`;
      } else if (this.designState.orderReferenceNumber) {
        return `order ${this.designState.orderReferenceNumber}`;
      } else if (this.designState.enquiryReferenceNumbers?.length) {
        return `enquir${this.designState.enquiryReferenceNumbers.length > 1 ? "ies" : "y"} ${this.designState.enquiryReferenceNumbers.join(", ")}`;
      }
    }
    return "";
  }

  get disablePriceTracker() {
    return !this.designState?.orderStatusId ? false : this.isAdminOrder && !this.canUpdateQuantity.includes(this.designState.orderStatusId);
  }

  get handleRemoveMask() {
    if (this.showTools) {
      return false;
    }

    if (this.selectedItem && !this.showTools) {
      return true;
    }

    if (this.selectedSection.id && this.selectedItem) {
      return true;
    }

    if (!this.selectedSection.id) {
      return true;
    }

    return false;
  }

  created() {
    window.dataLayer?.push({ event: "start_design" }); // GTM Event

    if (this.$route.query.order || this.$route.query.enquiry || this.$route.query.admin || this.$route.query.extra) {
      this.isAdminOrder = true;
    }

    if (this.$route.query.enquiry) {
      this.isAdminOrderEnquiry = true;
    }

    if (this.$route.query.store) {
      this.storeId = this.$route.query.store as string;
    }

    if (this.$route.query.extra) {
      this.isAdminConfigureExtra = true;
      this.adminConfigureExtraId = this.$route.query.extra as string;
    }

    if (this.$route.query.country) {
      store
        .dispatch("location/setDefaultSelection", {
          countryId: this.$route.query.country,
        })
        .then(() => {
          store.dispatch("location/getDefaultSelection");
        });
    }

    let roles = store.getters["user/role"];

    if (this.isAdminOrder && (!this.isLoggedIn || !this.hasAdminRoles(roles))) {
      this.$router.push({ name: "Login", query: { returnUrl: this.$route.fullPath, clear: "true" } });
      return;
    } else {
      this.user = store.getters["user/profile"];
    }

    this.selectedStyleDesignColourTab = "styles";
    const route = useRoute();

    if (route.query["saved-design"]) {
      this.savedCustomizedProductId = route.query["saved-design"].toString();
    } else if (route.query.product) {
      this.productId = route.query.product.toString();
    }

    if (this.savedCustomizedProductId) {
      this.loadCustomizedProduct(this.savedCustomizedProductId);
    } else if (this.productId) {
      this.getProduct(this.productId);
    } else {
      console.log("No product");
    }

    this.setClientHeight();
    this.mobileSetArrowOnLoad();

    this.$watch("selectedItem", () => {
      this.setSectionHeight();
    });
  }

  async getStoreInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.storeId) {
        this.loading = true;
        Stores.storesIdGet(this.storeId)
          .then(async (res) => {
            if (res.data.succeeded) {
              if (["Editing", "Rejected"].includes(res.data.resultData?.storeStatusId as string)) {
                this.storeInfo = res.data.resultData as StoreViewModel;

                store
                  .dispatch("location/setDefaultSelection", {
                    countryId: this.storeInfo.countryId,
                  })
                  .then(() => {
                    store.dispatch("location/getDefaultSelection");
                  });

                if (this.savedCustomizedProductId) {
                  await this.getStoreDesigns();
                }
              } else {
                this.storeId = "";
              }
            }
            this.loading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.storeId = "";
            this.loading = false;
            reject(error);
          });
      }
    });
  }

  async getExtraInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.adminConfigureExtraId) {
        this.loading = true;
        AdminExtras.adminExtrasIdGet(this.adminConfigureExtraId)
          .then(async (res) => {
            if (res.data.succeeded) {
              this.extraInfo = res.data.resultData as ExtraAdminViewModel;
              if(this.designState && this.extraInfo.customizedProductItemTemplate) {
                this.designState.items = [];
                this.designState.items.push(this.extraInfo.customizedProductItemTemplate);

                if(this.extraInfo.customizedProductItemTemplate.view == 1) {
                  this.selectLayer(1, false)
                }
              }
            }
            this.loading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.storeId = "";
            this.loading = false;
            reject(error);
          });
      }
    });
  }

  async getStoreDesigns(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.storeId && this.storeInfo.id) {
        this.loading = true;
        StoreCustomizedProducts.storeCustomizedProductsStoreIdGet(1, 9999999, this.storeInfo.id)
          .then((res) => {
            if (res.data.succeeded) {
              this.storeProducts = res.data.resultData?.items as Array<StoreCustomizedProductListViewModel>;
              if (this.savedCustomizedProductId) {
                const present = this.storeProducts.find((product) => product.id === this.savedCustomizedProductId);
                if (present) {
                  this.storeProduct = present;
                  this.isInStore = true;
                  this.quantity = this.storeProduct.expectedQuantity;
                }
              }
            }
            this.loading = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.storeId = "";
            this.loading = false;
            reject(error);
          });
      }
    });
  }

  openTools() {
    this.showTools = !this.showTools;

    setTimeout(() => {
      this.setSectionHeight();
    }, 0);
  }

  mobileSetArrowOnLoad() {
    let height = window.innerHeight;
    let width = window.innerWidth;

    var mediaMd = window.matchMedia("(max-width: 767.98px)");

    if (height > width || mediaMd.matches) {
      var mediaLg = window.matchMedia("(max-width: 991.98px)");
      if (mediaLg.matches) {
        this.selectedSection = { id: "", name: "", icon: "", disabledOnConfigureExtra: false };
        this.styleArrow = true;
      }
    }
  }

  async loadCustomizedProduct(customizedProductId: string, checkQty = true) {
    this.loading = true;
    await CustomizedProducts.customizedProductsIdGet(customizedProductId)
      .then(async (res) => {
        if (res.data.succeeded) {
          if (checkQty) {
            if (this.storeId) {
              await this.getStoreInfo().then(() => {
                if (this.storeProduct.id) {
                  this.quantity = this.storeProduct.expectedQuantity > 0 ? this.storeProduct.expectedQuantity : 25;
                  this.designTitle = this.storeProduct.name.substring(0, 20).trim();
                }
              });
            } else {
              this.quantity = res.data.resultData?.quantity || (25 as number);
            }
          }

          if (res.data.resultData?.quantity != 0) {
            this.lockQty = true;
          }

          this.productId = res.data.resultData!.productId;
          await ProductBuilder.productBuilderProductIdGet(this.productId)
            .then((resp) => {
              if (resp.data.succeeded) {
                this.apiRet = resp.data.resultData;
                this.designState = res.data.resultData;

                if (this.designState?.orderId && this.designState?.orderId == this.cartId) {
                  this.isInCurrentCart = true;
                }

                if (this.enableSave.customer == undefined && this.enableSave.admin == undefined) {
                  this.enableSave.customer = this.designState.customerCanUpdate;
                  this.enableSave.admin = this.designState.adminCanUpdate;
                }

                this.loadSavedDesign();
              }
            })
            .catch((error) => {
              console.log("LOAD PRODUCT FAILED", error);
              this.loading = false;
              this.handleError(error, true, "001 - An unexpected error occurred.")
            });
        }
      })
      .catch((error) => {
        console.log("LOAD CUSTOMIZED PRODUCT FAILED", error);
        this.loading = false;
        this.handleError(error, true, "002 - An unexpected error occurred.")
      });
    this.loading = false;
  }

  mounted() {
    window.addEventListener("resize", () => this.setSectionHeight());

    this.$watch("designState", () => {
      setTimeout(() => {
        this.setSectionHeight();
      }, 0);
    });
  }

  returnToPage(type: "store" | "oms") {
    if (type === "store") {
      if (this.isAdminOrder) {
        let link = `${process.env.VUE_APP_ROOT_ADMIN}/online-stores/edit-online-store/${this.storeInfo.id}`;
        window.open(link, "_self");
      } else {
        if (this.$route.query.adminView == "true") {
          this.$router.push({ name: "CustomStoreAdminStore", params: { id: this.storeInfo.id } });
        } else {
          this.$router.push({ name: "Profile", query: { section: "online-stores", store: this.storeInfo.id } });
        }
      }
    } else if (type === "oms") {
      let orderId = this.$route.query.order;
      let enquiryId = this.$route.query.enquiry;
      if (orderId) {
        let link = `${process.env.VUE_APP_ROOT_ADMIN}/orders/edit-order/${orderId}?tab=products`;
        window.open(link, "_self");
      } else if (enquiryId) {
        let link = `${process.env.VUE_APP_ROOT_ADMIN}/enquiries/edit-enquiry/${enquiryId}?tab=products`;
        window.open(link, "_self");
      } else if (this.adminConfigureExtraId) {
        let link = `${process.env.VUE_APP_ROOT_ADMIN}/products/extras/edit-extra/${this.adminConfigureExtraId}`;
        window.open(link, "_self");
      } else {
        this.$notify({ type: "error", text: "Redirect error", ignoreDuplicates: true, duration: -1 });
      }
    }
  }

  async generalOptionClick(id: string, toCart = false) {
    if (this.designing == true) {
      this.$notify({ type: "error", text: this.savePatternMsg, ignoreDuplicates: true, duration: -1 });
    } else {
      if (id === "saveas") {
        await this.saveOrUpdateDesign("saveas", false).then(async (res: any) => {
          if (res.data.succeeded) {
            if (this.storeInfo.id) {
              const recommendedPrice = await this.getPriceByExpectedQuantity({ id: res.data.resultData });
              const payload: StoreCustomizedProductsPostRequest = {
                name: (this.designTitle || this.designState.name).substring(0, 20).trim(),
                customizedProductId: res.data.resultData,
                storeId: this.storeInfo.id,
                expectedQuantity: this.quantity,
                price: recommendedPrice,
              };
              StoreCustomizedProducts.storeCustomizedProductsPost(payload)
                .then((res) => {
                  this.$notify({ type: "success", text: `Design added to your Online Store.`, ignoreDuplicates: true });
                  this.storeProduct = { ...res.data.resultData } as StoreCustomizedProductListViewModel;
                  this.isInStore = true;
                })
                .catch((error) => {
                  this.handleError(error)
                });
            } else {
              this.$notify({ type: "success", text: `New design saved.`, ignoreDuplicates: true });

              if (!this.isLoggedIn && this.anonMessageDisplayCount == 0) {
                this.showAnonSaveMessage = true;
                this.anonMessageDisplayCount += 1;
              }
            }
          }
        });
      }

      if (id === "share") {
        let savetype: SaveType = this.savedCustomizedProductId ? "save" : "saveas";

        await this.saveOrUpdateDesign(savetype, false)
          .then((res: any) => {
            if (res.data.succeeded) {
              this.shareId = "";
              let locHost = `${window.location.protocol}//${window.location.host}`;
              let designLink = `${locHost}/designer?saved-design=${res.data.resultData}`;

              let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
              if (!isMobile) {
                this.showShareModal = true;
                this.shareId = res.data.resultData;
                this.productTitle = `${this.apiRet.name ? this.apiRet.name : ""}`;
              } else {
                const sharing = async () => {
                  if (navigator.share) {
                    await navigator
                      .share({
                        title: "CLIFTON | Truly Custom Clothing",
                        text: "View my design created using CLIFTON!",
                        url: designLink,
                      })
                      .then(() => {
                        this.$notify({ type: "success", text: "Design link copied to clipboard." });
                      })
                      .catch((err) => {
                        if (err.toString().indexOf("cancel")) {
                          this.$notify({ type: "warning", text: "Copy of share link cancelled." });
                        } else {
                          this.$notify({ type: "error", text: `Error: ${err}` });
                        }
                      });
                  } else {
                    navigator.clipboard.writeText(designLink);
                    this.$notify({ type: "success", text: "The current link has been copied to your clipboard." });
                  }
                };

                sharing();
              }
            }
          })
          .catch((error) => {
            console.log("SHARE DESIGN FAILED", error);

            this.loading = false;
            this.handleError(error, true, "003 - An unexpected error occurred.")
          });
      }

      if (id === "enquire") {
        let leadInUrl = `${window.location.pathname}${window.location.search || ""}`;
        let q = this.quantity;
        let savetype: SaveType = this.savedCustomizedProductId ? "save" : "saveas";

        await this.saveOrUpdateDesign(savetype, false)
          .then((res: any) => {
            if (res.data.succeeded) {
              if (savetype === "save") {
                this.$router.push({ name: "Enquiries", query: { design: this.savedCustomizedProductId, from: leadInUrl, quantity: q } });
              } else {
                this.$router.push({ name: "Enquiries", query: { design: res.data.resultData, from: leadInUrl, quantity: q } });
              }
            }
          })
          .catch((error) => {
            console.log("ENQUIRE FAILED", error);
            this.loading = false;
            this.handleError(error, true, "004 - An unexpected error occurred.")
          });
      }

      if (id === "save") {
        if (this.isAdminConfigureExtra && this.adminConfigureExtraId && this.designState.items.length) {
          this.loading = true;

          await this.saveOrUpdateDesign("saveExtra", false)
          .then(async () => {
              await AdminExtraCustomizedProductItemTemplate.adminExtraCustomizedProductItemTemplateExtraIdPut(this.adminConfigureExtraId!, this.designState.items[0])
            .then(async (res) => {
              if (res.data.succeeded) {
                this.$notify({ type: "success", text: `Individual customisation saved.`, ignoreDuplicates: true });
                await this.getExtraInfo()
              }
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.handleError(error)
            });
          })
        } else {
          if (this.storeProduct.id) {
            this.storeProduct.name = (this.designTitle || this.storeProduct.name || this.designState.name).substring(0, 20).trim();
          }
          await this.saveOrUpdateDesign("save", false)
            .then(async (res: any) => {
              if (res.data.succeeded) {
                if (this.designState.orderId && this.canUpdateQuantity.includes(this.designState.orderStatusId)) {
                  await this.updateCartItem(res.data.resultData);
                }
                if (toCart && !this.storeInfo.id && this.cartId) {
                  this.$router.push({ name: "Cart", params: { id: this.cartId }, query: { sizesFor: this.itemUpdateSizeError ? this.savedCustomizedProductId : undefined } });
                } else if (this.storeInfo.id && this.isInStore) {
                  if (this.storeProduct.id) {
                    const payload: StoreCustomizedProductsIdDeleteRequest = {
                      name: this.storeProduct.name,
                      expectedQuantity: this.quantity,
                      price: this.storeProduct.price,
                    };
                    StoreCustomizedProducts.storeCustomizedProductsIdPut(this.storeProduct.id, payload)
                      .then((res) => {
                        this.storeProduct = { ...res.data.resultData } as StoreCustomizedProductListViewModel;
                        this.$notify({ type: "success", text: `Online Store design updated.`, ignoreDuplicates: true });
                      })
                      .catch((error) => {
                        this.handleError(error)
                      });
                  }
                } else {
                  this.$notify({ type: "success", text: `Design updated.`, ignoreDuplicates: true });

                  if (!this.storeInfo.id && !this.isLoggedIn && this.anonMessageDisplayCount == 0) {
                    this.showAnonSaveMessage = true;
                    this.anonMessageDisplayCount += 1;
                  }
                }
              }
            })
            .catch((error) => {
              console.log("SAVE FAILED", error);
              this.loading = false;
              this.handleError(error, true, "005 - An unexpected error occurred.")
            });
        }
      }
    }
  }

  async updateCartItem(id: string) {
    this.itemUpdateSizeError = false;
    this.loading = true;
    this.savedCustomizedProductId = id;

    await CartItems.cartItemsUpdateCartItemIdPut(this.savedCustomizedProductId, undefined, { totalQuantity: this.quantity })
      .then((res) => {
        if (res.data.succeeded) {
          // console.log(res);
        }
        this.loading = false;
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        errors.forEach((error: ApplicationError) => {
          if(error.friendlyMessage.includes("is not a valid size")) {
            this.itemUpdateSizeError = true;
          }
        });
        
        console.log(error);
        this.loading = false;
        this.handleError(error)
      });
  }

  async constructTextSVG(textObject: CustomizedProductItemViewModel) {
    this.fontPathKey += 1;
    let t = textObject;
    if (t?.text) {
      let eachLine = t.text.split("\n");
      let tspans: Array<string> = [];
      let alignment = t.alignment ? `text-anchor="${t.alignment}"` : "";

      eachLine.forEach((line: string) => {
        line.trim();
        tspans.push(`<tspan ${alignment} x="0" dy="1.2em" font-style="${t.fontStyle}" font-weight="${t.fontWeight}">${line}</tspan>`);
      });
      let border = "";

      if (t.border?.colour) {
        border = `stroke="${t.border.colour}" stroke-width="${t.border.width}"`;
      }

      let svg = `<svg id="textToPathSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" crossorigin="anonymous"><text font-size="${t.fontSize}" font-family="${t.font?.name}" fill="${t.colour}" ${border}>${tspans.join("")}</text></svg>`;

      let converted = (await this.convertTextSvgToPath(svg, t.font as any)) as any;

      return converted.outerHTML;
    }
  }

  async convertTextSvgToPath(svg: string, font: any) {
    let fontName = font.name;
    let output = await replaceAllInString(svg, {
      handlers: [mapHandler],
      fontMap: {
        [fontName]: {
          "400": `${this.uploadPath}/${font.fontFileUrl}`,
        },
      },
    });

    const outputResult = (this.$refs as any).textToPathResult;
    outputResult.removeAttribute("hidden");
    outputResult!.innerHTML = output;

    let outputSvg = document.getElementById("textToPathSvg") as any;
    if (outputSvg) {
      let group = outputSvg.children[0];
      let groupMetrics = group.getBBox();
      group.setAttribute("transform", `translate(${groupMetrics.x * -1 + 10} ${groupMetrics.y * -1 + 10})`);

      //clean up unnecessary attributes
      let attributeCleanUp = ["font-family", "font-size", "font-weight", "font-style"];
      attributeCleanUp.forEach((attribute: string) => {
        group.removeAttribute(attribute);
      });

      outputSvg.setAttribute("viewBox", `${0} ${0} ${groupMetrics.width + 20} ${groupMetrics.height + 20}`);
      outputSvg.setAttribute("height", `${groupMetrics.height + 20}`);
      outputSvg.setAttribute("width", `${groupMetrics.width + 20}`);
      outputSvg.removeAttribute("id");
      outputResult!.innerHTML = "";
      outputResult.setAttribute("hidden", true);
      return outputSvg;
    }
  }

  b64toBlob(b64Data: any, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async uploadImage(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }

  async saveOrUpdateDesign(saveType: SaveType, checkQty = true) {
    if (this.designing == true) {
      this.$notify({ type: "error", text: this.savePatternMsg, ignoreDuplicates: true, duration: -1 });
    } else {
      this.itemSelected(null);
      this.loading = true;
      let mySVG: any;
      let stateToSave: any;
      this.renderedView = 0;

      await this.$nextTick();

      mySVG = document.querySelector("#product-render");

      let frontBlob = "";
      frontBlob = mySVG!.outerHTML;

      this.renderedView = 1;
      await this.$nextTick(() => {
        mySVG = document.querySelector("#product-render");
      });

      let backBlob = "";
      backBlob = mySVG!.outerHTML;

      this.renderedView = -1;
      await this.$nextTick(() => {
        stateToSave = { ...this.designState };
      });

      let blob = new Blob([frontBlob], { type: "image/svg+xml" });
      let data = new FormData();
      data.append("file", blob, "saved_preview_front.svg");
      let path = await this.uploadImage(data);
      stateToSave.previewImageUrl = path;
      this.previewPath = path;

      blob = new Blob([backBlob], { type: "image/svg+xml" });
      data = new FormData();
      data.append("file", blob, "saved_preview_back.svg");
      path = await this.uploadImage(data);
      stateToSave.backPreviewImageUrl = path;
      stateToSave.title = this.designTitle;
      stateToSave.quantity = this.quantity;

      let svgIndex = 0;

      await Promise.all(
        stateToSave.items.map(async (item: CustomizedProductItemViewModel) => {
          if (item.type === "text") {
            let textPath = (await this.constructTextSVG(item)) as any;
            if (textPath) {
              svgIndex += 1;
              blob = new Blob([textPath], { type: "image/svg+xml" });
              data = new FormData();
              data.append("file", blob, `saved_text_as_path_${svgIndex}.svg`);
              path = await this.uploadImage(data);
              item.textSvgUrl = path;
            }
          }
        })
      );

      return new Promise<void>((resolve, reject) => {
        this.loading = true;
        if (this.savedCustomizedProductId && saveType === "save") {
          delete stateToSave.lastModifiedDate;

          if (stateToSave.items.length) {
            stateToSave.items.forEach((item: any) => {
              delete item.tempId;
            });
          }

          if (stateToSave.layers.length) {
            stateToSave.layers = stateToSave.layers.map((layer: any) => {
              return {
                id: layer.id,
                fabric: layer.fabric,
              };
            });
          }

          stateToSave.style = {
            id: stateToSave.style.id,
          };

          stateToSave.design = {
            id: stateToSave.design.id,
          };

          CustomizedProducts.customizedProductsIdPut(this.savedCustomizedProductId, stateToSave)
            .then(async (res: any) => {
              if (res.data.succeeded) {
                this.enableSave = {
                  customer: undefined,
                  admin: undefined,
                };
                await this.loadCustomizedProduct(this.savedCustomizedProductId, checkQty);
                if (!this.isLoggedIn) {
                  store.dispatch("user/addAnonymousSavedDesign", this.savedCustomizedProductId);
                }
                resolve(res);
              }
              this.loading = false;
            })
            .catch((error) => {
              console.log("SAVE / UPDATE DESIGN FAILED", error);
              this.loading = false;
              this.handleError(error, true, "006 - An unexpected error occurred.")
              reject(error);
            });
        } else if (saveType === "saveas") {
          delete stateToSave.id;
          delete stateToSave.lastModifiedDate;

          if (stateToSave.items.length) {
            stateToSave.items.forEach((item: any) => {
              delete item.id;
              delete item.tempId;
            });
          }

          CustomizedProducts.customizedProductsPost(stateToSave)
            .then(async (res: any) => {
              if (res.data.succeeded) {
                this.savedCustomizedProductId = res.data.resultData;
                this.$router.replace({ name: "Designer", query: { "saved-design": this.savedCustomizedProductId, origin: this.originSlug || undefined, store: this.storeInfo.id || undefined, adminView: this.$route.query.adminView ? "true" : undefined } });

                this.enableSave = {
                  customer: undefined,
                  admin: undefined,
                };

                await this.loadCustomizedProduct(this.savedCustomizedProductId, checkQty);
                if (!this.isLoggedIn) {
                  store.dispatch("user/addAnonymousSavedDesign", this.savedCustomizedProductId);
                }
                resolve(res);
              }
              this.loading = false;
            })
            .catch((error) => {
              console.log("CREATE CUSTOMISED PRODUCT FAILED", error);
              this.loading = false;
              this.handleError(error, true, "007 - An unexpected error occurred.")
              reject(error);
            });
        } else if (saveType === "saveExtra") {
          if (this.isAdminConfigureExtra && this.adminConfigureExtraId && this.designState.items.length) {
            this.designState.items[0] = stateToSave.items[0];
            this.designState.items[0].frontPreviewImageUrl = stateToSave.previewImageUrl
            this.designState.items[0].backPreviewImageUrl = stateToSave.backPreviewImageUrl
            resolve()
          }
        }
      });
    }
  }

  async getPriceByExpectedQuantity(product: any): Promise<number> {
    return new Promise((resolve, reject) => {
      if (this.storeInfo?.countryId) {
        CartItems.cartItemsCalculatePriceForCustomizedProductsPost(this.storeInfo?.countryId, false, [{ customizedProductId: product.id, quantity: +this.quantity }])
          .then((res) => {
            if (res.data.succeeded) {
              resolve(res.data.resultData?.price as number);
            }
          })
          .catch((error) => {
            console.log(error);
            this.handleError(error)
            reject(error);
          });
      } else {
        resolve(0);
      }
    });
  }

  saveAndSelectDefaultSizes(gotoCheckout = true, type: SaveType | null = null) {
    if (this.designing == true) {
      this.$notify({ type: "error", text: this.savePatternMsg, ignoreDuplicates: true, duration: -1 });
    } else {
      this.loading = true;
      let saveType: SaveType = this.designState.orderId || !this.savedCustomizedProductId ? "saveas" : "save";
      this.saveOrUpdateDesign(type || saveType, false).then((res: any) => {
        this.addToCart(gotoCheckout);
      });
    }
  }

  addToCart(gotoCheckout = true) {
    this.loading = true;

    if (this.savedCustomizedProductId) {
      store
        .dispatch("checkout/getCart")
        .then(async (cartId) => {
          await store
            .dispatch("checkout/addCartItem", {
              totalQuantity: this.quantity,
              customizedProductId: this.savedCustomizedProductId,
            })
            .then(async (res) => {
              let id = res.data.resultData.orderId;

              await store
                .dispatch("checkout/refreshCart", {
                  id: id,
                })
                .then(() => {
                  if (gotoCheckout) {
                    this.$router.push({ name: "Cart", params: { id: id } });
                  } else {
                    setTimeout(() => {
                      this.isInCurrentCart = true;
                      this.showAddToCartChoices = true;
                    }, 0);
                  }
                });
            });

          this.loading = false;
        })
        .catch((error) => {
          console.log("ADD TO CART FAILED", error);
          this.loading = false;
          this.handleError(error, true, "009 - An unexpected error occurred.")
        });
    }
    this.loading = false;
  }

  handleTriggerLocation(loc: any) {
    this.toolsLocation = loc;
  }

  setHeight(height: any) {
    this.maxChildHeight = height;
  }

  quantityChange(quantity: number) {
    this.quantity = quantity;
  }

  setSectionHeight(deselect = false) {
    const nodeNames = ["INPUT", "TEXTAREA"];
    const active = document.activeElement?.nodeName;

    if (active && nodeNames.includes(active)) {
      return;
    }

    let refs = this.$refs as any;
    if (refs.section) {
      this.sectionHeight = refs.section.clientHeight;
    }

    this.setClientHeight(deselect);
    // }
  }

  setClientHeight(deselect = false) {
    const nodeNames = ["INPUT", "TEXTAREA"];
    const active = document.activeElement?.nodeName;

    if (active && nodeNames.includes(active)) {
      return;
    }

    let height = window.innerHeight;
    let width = window.innerWidth;

    this.vh = height * 0.01;
    document.documentElement.style.setProperty("--vh", `${this.vh}px`);

    var mediaMd = window.matchMedia("(max-width: 767.98px)");

    if ((height > width || mediaMd.matches) && deselect) {
      var mediaLg = window.matchMedia("(max-width: 991.98px)");
      if (mediaLg.matches) {
        this.selectedSection = { id: "", name: "", icon: "", disabledOnConfigureExtra: false };
      }
    }
  }

  handleDisableFlag(sectionName: string) {
    if(!this.selectedStyle) {
      return false;
    }

    if (sectionName === 'Extra') {
      return this.selectedStyle.extras.length > 0;
    }
    else if (sectionName === 'Logo') {
      return this.getCustomizations('Logo').length > 0;
    }
    else if (sectionName === 'Text') {
      return this.getCustomizations('Text').length > 0
    }
    else {
      return true;
    }
  }

  handleControlSectionClick(section: ControlSection) {
    this.styleArrow = false;

    if (this.designing == true) {
      this.$notify({ type: "error", text: this.savePatternMsg, ignoreDuplicates: true, duration: -1 });
    } else {
      if (section.id == "text" && this.isAdminConfigureExtra && this.designState.items.length) {
        this.itemSelected(this.designState.items[0], false, true);
        (this.$refs.productView as ProductView).selectItem(this.designState.items[0], false, false);
        this.showTools = true;
      } else {
        this.itemSelected(null, false, true);
        this.showTools = false;
      }

      this.selectedTextFeature = null;
      this.selectedSection = section;

      setTimeout(() => {
        this.setSectionHeight();
      }, 0);
    }
  }

  handleClose(section: any) {
    if (this.designing) {
      this.setPrevColour = true;
    } else {
      if (this.selectedItem == null) {
        this.selectedSection = section;
        this.itemSelected(null);
        this.selectedTextFeature = null;
      }
      this.showTools = false;
      setTimeout(() => {
        this.setSectionHeight();
      }, 0);
    }
  }

  changeSelectedView(view: number) {
    this.selectedView = view;
    (this.$refs.productView as ProductView).selectItem(null);
  }

  async getProduct(ps: string) {
    this.allPrices = new Map();

    if (this.storeId) {
      await this.getStoreInfo();
    }

    this.productId = ps;
    ProductBuilder.productBuilderProductIdGet(ps)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.apiRet = res.data.resultData;
          this.allPrices.set("apiRet", this.apiRet.prices);
          this.selectStyle(this.apiRet.styles[0]);
          this.selectDesign(this.selectedStyle.designs[0]);
          this.$router.replace({ name: "Designer", query: { product: ps, origin: this.originSlug || undefined, store: this.storeInfo.id || undefined, adminView: this.$route.query.adminView ? "true" : undefined, extra: this.adminConfigureExtraId || undefined } });
          this.quantity = this.apiRet.defaultQuantity || (25 as number);
          this.updateDesignState();

          if (this.adminConfigureExtraId) {
            await this.getExtraInfo();
          }
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log("GET PRODUCT FAILED", error);
        debugger;
        this.loading = false;
        let hasProp = error ? Object.prototype.hasOwnProperty.call(error, "response") : null;
        if (!hasProp && !error) {
          this.$notify({ type: "error", text: "010 - An unexpected error occurred.", ignoreDuplicates: true, duration: -1 });
          this.$router.push({ name: "AllProductSelector" }); //push doesnt seem to work here
        } else if(error) {
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        }
      });
  }

  resetTemplate() {
    this.updateDesignState();
    this.handleControlSectionClick(this.sections[0]);
    this.mobileSetArrowOnLoad();
    this.itemSelected(null, false, true)
  }

  updateDesignState() {
    var frontOverlay = this.selectedStyle.frontShadowMaskUrl;
    var backOverlay = this.selectedStyle.backShadowMaskUrl;

    if (!this.selectedDesign.layers.length) {
      this.$notify({ type: "error", text: "This product is missing layers.", ignoreDuplicates: true, duration: -1 });
      this.$router.push({ name: "AllProductSelector" }); //push doesnt seem to work here
      return;
    }

    this.selectedDesign.fabricChannels.forEach((channel: ProductBuilderFabricChannelViewModel) => {
      (channel as any).selectedId = channel.fabrics[0]?.id;
    });

    var layers = this.selectedDesign.layers.map((layer: any) => {
      const fabricChannel: any = this.selectedDesign.fabricChannels.find((x: any) => layer.fabricChannelId === x.id);
      let colour: any = null;

      if (!layer?.fabrics.length) {
        this.$notify({ type: "error", text: "This product is missing fabrics.", ignoreDuplicates: true, duration: -1 });
        this.$router.push({ name: "AllProductSelector" }); //push doesnt seem to work here
        return;
      }

      if (!layer?.fabrics[0]?.colours.length) {
        this.$notify({ type: "error", text: "This product is missing colours.", ignoreDuplicates: true, duration: -1 });
        this.$router.push({ name: "AllProductSelector" }); //push doesnt seem to work here
        return;
      }

      for (let i = 0; i < layer.fabrics[0].colours.length; i++) {
        if (!layer.fabrics[0]?.colours[i]?.patternImageUrl && layer.fabrics[0]?.colours[i]?.hexValue) {
          colour = layer.fabrics[0]?.colours[i];
          break;
        }
      }

      if (!colour) {
        colour = layer.fabrics[0].colours[0];
      }

      return {
        fabricChannelId: layer.fabricChannelId,
        fabric: this.mapFabricColour(colour, fabricChannel?.fabrics[0] || layer.fabrics[0]),
        code: layer.code,
        id: layer.id,
        name: layer.name,
        backMaskUrl: `${layer.backMaskUrl}`,
        frontMaskUrl: `${layer.frontMaskUrl}`,
        prices: [...layer.prices],
      };
    });

    let infoToRetain = null;
    if (this.designState) {
      infoToRetain = {
        adminCanUpdate: this.designState?.adminCanUpdate,
        customerCanUpdate: this.designState?.customerCanUpdate,
        enquiryReferenceNumbers: this.designState?.enquiryReferenceNumbers,
        orderReferenceNumber: this.designState?.orderReferenceNumber,
        orderId: this.designState?.orderId,
        orderStatusId: this.designState?.orderStatusId,
        // extras: this.designState.extras
      };
    }

    this.designState = {
      layers,
      productId: this.productId,
      design: {
        id: this.selectedDesign.id,
      },
      style: {
        id: this.selectedStyle.id,
      },
      previewImageUrl: ``,
      backPreviewImageUrl: "",
      frontOverlay: `${frontOverlay}`,
      backOverlay: `${backOverlay}`,
      items: [],
      extras: [],
      // productId: this.apiRet.id,
      title: this.designTitle,
    };
    if (infoToRetain && this.designState) {
      this.designState.adminCanUpdate = infoToRetain.adminCanUpdate;
      this.designState.customerCanUpdate = infoToRetain.customerCanUpdate;
      this.designState.enquiryReferenceNumbers = infoToRetain.enquiryReferenceNumbers;
      this.designState.orderReferenceNumber = infoToRetain.orderReferenceNumber;
      this.designState.orderId = infoToRetain.orderId;
      this.designState.orderStatusId = infoToRetain.orderStatusId;
      // this.designState.extras = [...infoToRetain.extras]
    }

    this.allPrices = new Map();
    this.allPrices.set("apiRet", this.apiRet.prices);
    this.allPrices.set(this.selectedStyle.id, this.selectedStyle.prices);
    this.allPrices.set(this.selectedDesign.id, this.selectedDesign.prices);

    this.designState.layers.forEach((layer: any) => {
      let colour = layer.fabric.colour;
      if (layer.prices.length) {
        this.allPrices.set(layer.id, layer.prices);
      }

      if (layer.fabric.price) {
        let prices = [
          {
            id: "",
            maximumQuantity: null,
            minimumQuantity: null,
            price: layer.fabric.price,
          },
        ];
        this.allPrices.set(`${layer.id}${this.magicSeparator}${layer.fabric.id}`, prices);
      }

      if (colour.prices.length) {
        this.allPrices.set(`${layer.id}${this.magicSeparator}${colour.id}`, colour.prices);
      }
    });
  }

  loadSavedDesign() {
    this.loading = true;
    this.allPrices = new Map();
    this.designTitle = this.storeProduct.name || this.designState.title || "";
    this.allPrices.set("apiRet", this.apiRet.prices);
    let styles = this.apiRet.styles;

    styles.map((style: any) => {
      if (style.id === this.designState.style.id) {
        this.selectStyle(style);
      }
    });

    let designs = this.selectedStyle.designs;

    designs.map((design: any) => {
      if (design.id === this.designState.design.id) {
        this.selectedDesign = design;
      }
    });

    this.allPrices.set(this.selectedStyle.id, this.selectedStyle.prices);
    this.allPrices.set(this.selectedDesign.id, this.selectedDesign.prices);

    let customizations = Object.assign({}, ...this.selectedDesign.customizations.map((x: any) => ({ [x.id]: x })));
    this.designState.items.forEach((item: any) => {
      var customizationFromProduct = customizations[item.customization.id];
      const guid = this.guid();
      item.tempId = guid;
      if (customizationFromProduct) {
        item.customization = { ...customizationFromProduct };
      }

      if(!item.border?.colour) {
        item.border = null;
      }

      if(!item.extraId) {
        this.allPrices.set(`${item.customization.id}${this.magicSeparator}${item.tempId}`, item.customization.prices);
      } else {
        delete item.customization.prices;
      }
    });

    this.designState.items = this.designState.items.sort((a: any, b: any) => {
      return a.order - b.order;
    });

    let extras = Object.assign({}, ...this.selectedStyle.extras.map((x: any) => ({ [x.id]: x })));
    this.designState.extras.forEach((extra: any, index:any) => {
      const extraFromProduct = extras[extra.id];
      const isIndividualCustomization = this.designState.items.some((item:any) => item.extraId == extra.id)
      let extraCustomizationId = "";
      let customizationFromProduct = null;

      extra.isIndividualCustomization = isIndividualCustomization;

      if(isIndividualCustomization && !extraFromProduct?.customizedProductItemTemplate) {
        // if template removed, remove item
        this.designState.items = this.designState.items.filter((item:any) => item.extraId != extra.id);
      }

      if(!isIndividualCustomization && extraFromProduct?.customizedProductItemTemplate) {
        // if template is new, add item and update extra
        const customizedProductItemTemplate:any = extraFromProduct.customizedProductItemTemplate;
        let newItem = { 
          ...extraFromProduct,
          colour: customizedProductItemTemplate.colour,
          font: customizedProductItemTemplate.font,
          customization: customizedProductItemTemplate.customization,
          border: null,
          isIndividualCustomization: true
        };
        delete newItem.customizedProductItemTemplate;

        const guid = this.guid();
        customizedProductItemTemplate.tempId = guid;

        const length = this.designState.items.length;
        if (length > 0) {
          customizedProductItemTemplate!.order = this.designState.items[length - 1].order + 1;
        }

        const hasMatchingCustomization = this.getCustomizations("Extra").find(customization => customization.id == customizedProductItemTemplate?.customization.id)

        if(!hasMatchingCustomization) {
          customizedProductItemTemplate.customization = this.getCustomizations("Extra")?.[0];
        }
        
        this.designState.items.push({extraId: extra.id, isFromLibrary: true, ...customizedProductItemTemplate});

        extra = { ...newItem };
        this.designState.extras[index] = { ...newItem };
      }

      if (extraFromProduct && extra.hasCustomizationText) {
        extraCustomizationId = extra.customization.id;
        customizationFromProduct = customizations[extraCustomizationId];
        this.allPrices.set(`${extra.id}${this.magicSeparator}${extraCustomizationId}`, customizationFromProduct.prices);
      }     

      if (extraFromProduct) {
        extra = { ...extraFromProduct };
        this.allPrices.set(extra.id, extra.prices);
      } else {
        this.designState.extras = this.designState.extras.filter((item:any) => item.id != extra.id);
        this.$notify({ type: "error", text: `The extra - ${extra.name} - is no longer available and has been ommited. Save to update your design.`, ignoreDuplicates: true, duration: -1 });
        console.log(`Extra omitted - ${extra.name} (${extra.code})`)
      }
      
      return extra;
    });

    let layers = Object.assign({}, ...this.selectedDesign.layers.map((x: any) => ({ [x.id]: x })));
    this.designState.layers.forEach((layer: any) => {
      let selectedColourId = layer.fabric.colour.id;
      let layerFromProduct = layers[layer.id];
      let layerColour = {} as any;

      if (layerFromProduct.prices.length) {
        this.allPrices.set(layerFromProduct.id, layerFromProduct.prices);
      }

      layerFromProduct.fabrics.forEach((fabric: any) => {
        fabric.colours.forEach((colour: any) => {
          if (colour.id === selectedColourId) {
            layerColour = { ...colour };
            return;
          }
        });
      });

      if (layerColour && Object.prototype.hasOwnProperty.call(layerColour, "prices") && layerColour.prices.length) {
        this.allPrices.set(`${layer.id}${this.magicSeparator}${layerColour.id}`, layerColour.prices);
      }

      if (layer.fabric.price) {
        let prices = [
          {
            id: "",
            maximumQuantity: null,
            minimumQuantity: null,
            price: layer.fabric.price,
          },
        ];
        this.allPrices.set(`${layer.id}${this.magicSeparator}${layer.fabric.id}`, prices);
      }

      layer.fabricChannelId = layer.fabricChannel?.id || layerFromProduct.fabricChannelId || null;

      this.setCustomPatternPrice(layer, { fabric: layer.fabric, colour: layer.fabric.colour }, true, true);
    });

    this.selectedDesign.fabricChannels.forEach((channel: ProductBuilderFabricChannelViewModel) => {
      const layer: any = this.designState.layers.find((x: any) => x.fabricChannelId === channel.id);
      if (layer) {
        (channel as any).selectedId = layer.fabric.id;
      }
    });

    this.loading = false;
  }

  swapView(item: any, view: number | null) {
    var test = this.designState.items.filter((i: any) => i == item)[0];
    test.view = (test.view + 1) % 2;
    this.selectLayer(test.view, false);
  }

  addObjectToDesign(item: any, view: number | null, isDuplicate = false) {
    const length = this.designState.items.length;
    let _item = { ...item, view: this.selectedView };

    if (length > 0) {
      _item.order = this.designState.items[length - 1].order + 1;
    }
    this.designState.items.push(_item);
    (this.$refs.productView as ProductView).selectItem(_item, false, isDuplicate);
  }

  mapFabricColour(colour: any, fabric: any) {
    return {
      id: fabric.id,
      price: fabric.price,
      customPatternPrice: fabric.customPatternPrice,
      colour: { ...colour },
    };
  }

  setFabric(selection: { fabrics: any; fabricChannelId: string; backupColour: any }) {
    this.designState.layers.forEach((layer: any) => {
      if (selection.fabricChannelId) {
        if (Object.prototype.hasOwnProperty.call(selection.fabrics, layer.id)) {
          const colour = selection.fabrics[layer.id].colours.find((x: any) => x.id === layer.fabric.colour.id);
          if (colour || selection.backupColour) {
            this.setLayerColour({ layer: layer, fabric: selection.fabrics[layer.id], colour: colour || selection.backupColour });
          }
        }
      } else {
        if (layer.fabricChannelId === selection.fabricChannelId) {
          const colour = selection.fabrics.colours.find((x: any) => x.id === layer.fabric.colour.id);
          if (colour || selection.backupColour) {
            this.setLayerColour({ layer: layer, fabric: selection.fabrics, colour: colour || selection.backupColour });
          }
        }
      }
    });
  }

  setLayerColour(selection: any) {
    var layer = this.designState.layers.find((layer: any) => layer.id == selection.layer.id);
    if (layer) {
      let key = "" as any;
      for (key of this.allPrices.keys()) {
        let splitPrice = key.split(this.magicSeparator) as any;
        if (splitPrice.length == 2 && splitPrice[0] === layer.id && splitPrice[1] === layer.fabric.colour.id) {
          this.allPrices.delete(key);
        }
        if (splitPrice.length == 2 && splitPrice[0] === layer.id && splitPrice[1] === layer.fabric.id) {
          this.allPrices.delete(key);
        }
      }

      layer.fabric = this.mapFabricColour(selection.colour, selection.fabric);

      if (layer.fabric.price) {
        let prices = [
          {
            id: "",
            maximumQuantity: null,
            minimumQuantity: null,
            price: layer.fabric.price,
          },
        ];
        this.allPrices.set(`${layer.id}${this.magicSeparator}${layer.fabric.id}`, prices);
      }

      if (Object.prototype.hasOwnProperty.call(selection.colour, "prices") && selection.colour.prices.length) {
        this.allPrices.set(`${layer.id}${this.magicSeparator}${selection.colour.id}`, selection.colour.prices);
      }

      this.setCustomPatternPrice(layer, { colour: selection.colour, fabric: selection.fabric });
    }
  }

  setCustomPatternPrice(layer: any, selection: any, onlyAdd = false, checkLocal = false) {
    let price = null;

    if (!onlyAdd) {
      let key = "" as any;
      for (key of this.allPrices.keys()) {
        let splitPrice = key.split(this.magicSeparator) as any;
        if (splitPrice.length == 2 && splitPrice[0] === layer.id && splitPrice[1] === `${selection.fabric.id}customPattern`) {
          this.allPrices.delete(key);
        }
      }
    }

    let hasId = Object.prototype.hasOwnProperty.call(selection.colour, "id");

    if (!hasId || (hasId && !selection.colour.id)) {
      if (checkLocal) {
        let customPatterns = store.getters["user/customPatterns"];
        if (customPatterns?.length) {
          let customPattern = customPatterns.find((item: CustomPattern) => (item.patternImageUrl == selection.colour.patternImageUrl) && (item.highQualityPatternImageUrl === selection.colour.highQualityPatternImageUrl));
          if (!customPattern) {
            store.dispatch("user/addCustomPattern", { patternImageUrl: selection.colour.patternImageUrl, highQualityPatternImageUrl: selection.colour.highQualityPatternImageUrl || null });
          }
        } else {
          store.dispatch("user/addCustomPattern", { patternImageUrl: selection.colour.patternImageUrl, highQualityPatternImageUrl: selection.colour.highQualityPatternImageUrl || null });
        }
      }

      if (selection.fabric.customPatternPrice > 0) {
        price = [
          {
            id: "",
            maximumQuantity: null,
            minimumQuantity: null,
            price: selection.fabric.customPatternPrice,
          },
        ];
      }

      if (Object.prototype.hasOwnProperty.call(selection.fabric, "customPatternPrice") && price) {
        this.allPrices.set(`${layer.id}${this.magicSeparator}${selection.fabric.id}customPattern`, price);
      }
    }
  }

  itemSelected(item: any, isDuplicate = false, force = false) {
    if(item && item.extraId) {
      this.selectedTextFeature = this.designState.extras.find((extra: any) => extra.id == item.extraId) || null;
    } else if(item) {
      this.selectedTextFeature = null;
    }
    
    if (
    force
    || !this.isAdminConfigureExtra
    || (this.isAdminConfigureExtra && item && this.selectedSection.id === "text")
    || (this.isAdminConfigureExtra && this.selectedSection.id !== "text")
    ) {
      this.selectedItem = item;
      if(item) {
        (this.$refs.productView as ProductView).selectItem(this.selectedItem, true);
      }
    }

    if (isDuplicate) {
      this.selectItemCustomization(item.customization);
    }

    if (item == null) {
      (this.$refs.productView as ProductView).selectItem(null, true);
      if(this.isAdminOrder && this.selectedTextFeature?.isIndividualCustomization) {
        this.selectedTextFeature = null;
      }
    } else {
      this.styleArrow = false;
      setTimeout(() => {
        if(!this.selectedTextFeature) {
          this.setSectionHeight(true);
        }
        document.getElementById("selectedChild")?.scroll({top:0,behavior:'smooth'});
      }, 0);
    }
  }

  selectLayer(viewIndex: number, deselect = true) {
    this.selectedView = viewIndex;
    if (deselect) {
      let refs = this.$refs as any;
      if (Object.prototype.hasOwnProperty.call(refs, "productView")) {
        (refs.productView as ProductView).selectItem(null);
      }
    }
  }

  layer(direction: number, itemOverride: any | null = null) {
    // find selectedItem position in designstate.items
    let selectedItemIndex = this.designState.items.findIndex((item: any) => item.order == ((itemOverride && itemOverride.order) || this.selectedItem!.order));
    let itemsLength = this.designState.items.length;

    if (direction == 1) {
      if (selectedItemIndex != itemsLength - 1) {
        this.designState.items[selectedItemIndex].order += 1;
        this.designState.items[selectedItemIndex + 1].order -= 1;
      }
    } else {
      if (selectedItemIndex != 0) {
        this.designState.items[selectedItemIndex].order -= 1;
        this.designState.items[selectedItemIndex - 1].order += 1;
      }
    }

    this.designState.items = this.designState.items.sort((a: any, b: any) => {
      return a.order - b.order;
    });
  }

  removeElement(item: any, skip = false) {
    let itemIndex = this.designState.items.findIndex((i: any) => i.order == item.order);
    if (this.designState.items.length > 2) {
      this.designState.items.forEach((i: any, index: number) => {
        if (index > itemIndex) {
          i.order -= 1;
        }
      });
    }

    if (this.selectedItem && Object.prototype.hasOwnProperty.call(this.selectedItem, "tempId")) {
      if (item.customization) {
        this.allPrices.delete(`${item.customization.id}${this.magicSeparator}${this.selectedItem.tempId}`);
      }
      this.designState.items = this.designState.items.filter((element: any) => element != this.selectedItem);
      this.selectedItem = null;
      (this.$refs.productView as ProductView).selectItem(null, true);
    }
    
    if(!skip && item?.extraId) {
      this.addRemoveTextFeature(item)
    } 

    setTimeout(() => {
      this.setSectionHeight();
    }, 0);
  }

  getCustomizations(typeId: CustomizationType) {
    return this.selectedDesign.customizations.filter(c => c.customizationTypeId == typeId);
  }

  clearTextFeatureCustomization(item: SelectedFeatureItem) {
    if (item) {
      this.allPrices.delete(`${item.id}${this.magicSeparator}${item.customization.id}`);
    }

    if(Object.prototype.hasOwnProperty.call(item, "isIndividualCustomization") && item.isIndividualCustomization) {
      this.removeElement(item);
    }

    this.selectedTextFeature = null;
  }

  addRemoveTextFeature(item: any, update: any = null, isExtraDismissed = true) {
    if (update) {
      this.designState.extras.forEach((extra: any, index: any) => {
        if (extra.id === item.id) {
          this.designState.extras[index] = { ...item };
          if (isExtraDismissed) {
            this.selectedTextFeature = null;
            this.itemSelected(null, false, true)
          }
        }
      });
    } else {
      const idToUse = Object.prototype.hasOwnProperty.call(item, "extraId") ? item.extraId : item.id;
      if (this.designState.extras.find((i: any) => i.id == idToUse)) {
        
        this.designState.extras = this.designState.extras.filter((i: any) => i.id != idToUse);
        this.allPrices.delete(idToUse);
        if (item.customization) {
          this.allPrices.delete(`${idToUse}${this.magicSeparator}${item.customization.id}`);
        }
        if (isExtraDismissed) {
          this.selectedTextFeature = null;
          this.itemSelected(null, false, true)
        }
        if(Object.prototype.hasOwnProperty.call(item, "isIndividualCustomization") && item.isIndividualCustomization) {
          this.selectedItem = this.designState.items.find((x:any) => x.extraId === idToUse);
          (this.$refs.productView as ProductView).selectItem(this.selectedItem, true)
          this.removeElement(this.selectedItem, true);
        }
      } else {
        this.designState.extras.push(item);
        this.allPrices.set(idToUse, item.prices);
        if (isExtraDismissed) {
          this.selectedTextFeature = null;
          this.itemSelected(null, false, true)
        }
      }
    }
    this.showTools = false;
  }

  toggleFeature(item: ProductBuilderExtraViewModel) {
    let extra = this.designState.extras.find((i: any) => i.id == item.id);
    if (extra) {
      this.validateExtra(extra, item)
      .then((isValid) => {
        if(isValid) {
          this.showTools = true;
          if (item.hasCustomizationText) {
            const itemExtra = this.designState.items.find((x:any) => x.extraId == extra.id);
            this.selectedTextFeature = { ...extra };

            if(itemExtra) {
              this.selectLayer(itemExtra.view);
              this.itemSelected(itemExtra)
            }
          } else {
            this.designState.extras = this.designState.extras.filter((i: any) => i.id != item.id);
            this.allPrices.delete(item.id);
          }
        }
      })
    } else {
      this.showTools = true;
      this.allPrices.set(item.id, item.prices);
      if (item.hasCustomizationText) {
        if(!item.customizedProductItemTemplate) {
          let newItem = {
            ...item,
            colour: "",
            font: {
              id: "",
              name: "",
              fontFileUrl: "",
            },
            customization: {
              id: "",
              name: "",
              code: "",
              customizationTypeId: CustomizationType.Extra,
            },
            border: null,
            isIndividualCustomization: false
          };
          this.designState.extras.push(newItem);
          this.selectedTextFeature = { ...newItem };
        } else {
          const customizedProductItemTemplate = item.customizedProductItemTemplate;
          let newItem = { 
            ...item,
            colour: customizedProductItemTemplate.colour,
            font: customizedProductItemTemplate.font,
            customization: customizedProductItemTemplate.customization,
            border: null,
            isIndividualCustomization: true
          };
          delete newItem.customizedProductItemTemplate;

          const guid = this.guid();
          (customizedProductItemTemplate as any).tempId = guid;

          const length = this.designState.items.length;
          if (length > 0) {
            customizedProductItemTemplate!.order = this.designState.items[length - 1].order + 1;
          }

          const hasMatchingCustomization = this.getCustomizations("Extra").find(customization => customization.id == customizedProductItemTemplate?.customization.id)
          this.selectedTextFeature = newItem;
          this.designState.extras.push(newItem);

          if(!hasMatchingCustomization) {
            this.selectedTextFeature.customization = customizedProductItemTemplate.customization = this.getCustomizations("Extra")?.[0];
          }
          
          this.designState.items.push({extraId: item.id, isFromLibrary: true, ...customizedProductItemTemplate});
          this.selectLayer(customizedProductItemTemplate.view);
          this.itemSelected(this.designState.items[this.designState.items.length - 1])
        }
      } else {
        this.designState.extras.push(item);
      }
    }
  }

  validateExtra(extra:any, item: ProductBuilderExtraViewModel) {
    return new Promise((resolve, reject) => {
      const itemExtra = this.designState.items.find((x:any) => x.extraId == extra.id);
      const noLongerTextExtra = itemExtra && (!item.hasCustomizationText || !item.customizedProductItemTemplate);
      const hasBeenAltered = (!itemExtra && item.customizedProductItemTemplate) || extra.hasCustomizationText != item.hasCustomizationText

      if(noLongerTextExtra || hasBeenAltered) {
        this.designState.extras = this.designState.extras.filter((i: any) => i.id != item.id);
        this.designState.items = this.designState.items.filter((i: any) => i.extraId != extra.id);
        this.allPrices.delete(item.id);
        resolve(false)
      } else {
        resolve(true)
      }
    }) 
  }

  layers = ["Front", "Back"];

  selectedView = 0;
  renderedView = -1; // This is used to render the svg content so we can save it

  apiRet: any;

  design: any[] = [];

  selectStyle(style: ProductBuilderStyleViewModel) {
    this.selectedStyle = style;
    //If it breaks blame Liam for the Guid collision ❤️
  }

  selectDesign(design: ProductBuilderDesignViewModel) {
    this.selectedDesign = design;
    this.updateDesignState();
  }

  selectItemCustomization(customization: ProductBuilderCustomizationViewModel) {
    if (customization) {
      if(!this.selectedItem?.extraId) {
        if (!Object.prototype.hasOwnProperty.call(this.selectedItem, "tempId")) {
          const guid = this.guid();
          this.selectedItem!.tempId = guid;
        }
        if (Object.prototype.hasOwnProperty.call(this.selectedItem, "tempId")) {
          if (this.selectedItem!.customization) {
            this.allPrices.delete(`${this.selectedItem!.customization.id}${this.magicSeparator}${this.selectedItem!.tempId}`);
          }
          this.selectedItem!.customization = customization;
          this.allPrices.set(`${customization.id}${this.magicSeparator}${this.selectedItem!.tempId}`, customization.prices);
        }
      } else {
        this.selectFeatureCustomization(customization, this.selectedTextFeature)
      }
    }
  }

  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  guid() {
    return this.S4() + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4();
  }

  selectFeatureCustomization(customization: ProductBuilderCustomizationViewModel, selectedFeature: any) {
    if (customization) {
      if (selectedFeature.customization) {
        this.allPrices.delete(`${selectedFeature.id}${this.magicSeparator}${selectedFeature.customization.id}`);
      }
      selectedFeature.customization = customization;
      this.allPrices.set(`${selectedFeature.id}${this.magicSeparator}${selectedFeature.customization.id}`, customization.prices);

      this.designState.extras.forEach((extra: any, index: any) => {
        if (extra.id === selectedFeature.id) {
          this.designState.extras[index] = { ...selectedFeature };
        }
      });

      if(Object.prototype.hasOwnProperty.call(selectedFeature, "isIndividualCustomization") && selectedFeature.isIndividualCustomization) {
        const itemExtra = this.designState.items.find((item:any) => item.extraId == selectedFeature.id)
        if(itemExtra) {
          itemExtra.customization = customization;
        }
      }
    }
  }

  filterSections() {
    return this.sections;
  }
}
