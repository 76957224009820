
import { PriceViewModel } from "@/api-client";
import { BankersRounding, CalculatePrice, Currency } from "@/mixins/utilities";
import { mixins, Options, Vue } from "vue-class-component";
import BasicVueModal from "@/components/misc/BasicVueModal.vue";

@Options({
  props: {
    prices: {default: null},
    minQuantity: {default: 1},
    quantity: {default: 25},
    lock: {default: false},
    storeId: { default: null },
    forceDisable: {default: false}
  },
  components: { BasicVueModal },
  emits: ['allowQuantityChange', 'quantityChange']
})
export default class PriceTracker extends mixins(BankersRounding, CalculatePrice, Currency) {
  quantity = 25;
  prices: Map<string, any> = new Map();
  minQuantity = 1;
  quantityClone = 25;
  lock = false;
  forceDisable = false;
  showQuantityPrompt = false;

  created() {
    this.quantityClone = this.quantity
  }

  get price() {
    if(this.prices.size > 0){
      let price = 0;
      for (const [key, value] of this.prices.entries()) {
        if(value) {
          var range = value.filter((x: PriceViewModel) => (x.minimumQuantity ?? Number.MIN_SAFE_INTEGER) <= this.quantityClone && (x.maximumQuantity ?? Number.MAX_SAFE_INTEGER) >= this.quantityClone)[0];
          if(range != null)
          {
            price += range.price;
          }
        }
      }
      return price;
    }
    return 0;
  }

  increment() {
    this.updateQuantity(this.quantityClone + 1);
  }

  decrement() {
    this.updateQuantity(this.quantityClone - 1);
  }

  updateQuantity(q: number) {
    if(!this.forceDisable) {
      if(!this.lock) {
        if(q < this.minQuantity) {
          q = this.minQuantity;
          this.$notify({ type: "error", text: `The minimum quantity for this product is ${this.minQuantity}.`, ignoreDuplicates: true, duration: -1 });
        } 
        this.quantityClone = q

        this.$emit('quantityChange', this.quantityClone)
      } else {
        this.showQuantityPrompt = true;
      }
    } else {
      this.quantityClone = this.quantity;
    }
  }

  allowQuantityChange() {
    if(!this.forceDisable) {
      this.showQuantityPrompt = false;
      this.$emit('allowQuantityChange')
      this.$notify({ type: "success", text: `Quantity can now be changed.`, ignoreDuplicates: true });
    }
  }
}
